import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InvoiceRepository } from '../../repositories/invoice/InvoiceRepository';
import { UploadFileRequestDTO, UploadFileResponseDTO } from 'src/app/data/dto/InvoicesDTO';

@Injectable({
    providedIn: 'root'
})

export class UploadFileInvoiceUseCase implements UseCase<UploadFileRequestDTO, UploadFileResponseDTO> {
    constructor(
        private invoiceRepository: InvoiceRepository,
    ) { }

    execute(request: UploadFileRequestDTO): Observable<UploadFileResponseDTO> {
        return this.invoiceRepository.uploadFileInvoice(request);
    }
}
