import { Observable } from 'rxjs';
import { ReportsAdminRepository } from 'src/app/core/repositories/reports/ReportsAdminRepository';
import { UseCase } from './../../base/UserCase';
import { Injectable } from '@angular/core';
import { ListEstadosDTO } from 'src/app/data/dto/ReportsDTO';
@Injectable({
    providedIn: 'root',
  })

  export class ListStateUseCase implements UseCase<string, ListEstadosDTO>{
      constructor(private reportsAdminRepository: ReportsAdminRepository){}
      execute(): Observable<ListEstadosDTO> {
        return this.reportsAdminRepository.listState();
      }
  }
