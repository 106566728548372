import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InvoiceRepository } from '../../repositories/invoice/InvoiceRepository';
import { ListScreenDTO } from 'src/app/data/dto/InvoicesDTO';

@Injectable({
  providedIn: 'root',
})

export class ListScreensUseCase
  implements UseCase<string, ListScreenDTO>
{
  constructor(private invoiceRepository: InvoiceRepository) {}

  execute(): Observable<ListScreenDTO> {
    return this.invoiceRepository.listScreen();
  }
}
