import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UploadTaxRepository } from '../../repositories/upload-tax/UploadTaxRepository';
import { UploadTaxRequestDTO, UploadTaxResponseDTO } from 'src/app/data/dto/UploadTaxDTO';

@Injectable({
    providedIn: 'root'
})

export class UploadDocumentTaxUseCase implements UseCase<UploadTaxRequestDTO, UploadTaxResponseDTO> {
    constructor(
        private uploadTaxRepository: UploadTaxRepository,
    ) { }

    execute(request: UploadTaxRequestDTO): Observable<UploadTaxResponseDTO> {
        return this.uploadTaxRepository.uploadDocumentTax(request);
    }

}
