import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AcceptInvoicesUserRequestDTO, AcceptInvoicesUserResponseDTO } from 'src/app/data/dto/InvoicesDTO';
import { InvoiceRepository } from 'src/app/core/repositories/invoice/InvoiceRepository';

@Injectable({
    providedIn: 'root'
})

export class AcceptInvoicesUserUseCase implements UseCase<AcceptInvoicesUserRequestDTO, AcceptInvoicesUserResponseDTO> {
    constructor(
        private invoiceRepository: InvoiceRepository,
    ) { }

    execute(request: AcceptInvoicesUserRequestDTO): Observable<AcceptInvoicesUserResponseDTO> {
        return this.invoiceRepository.acceptInvoicesUser(request);
    }
}
