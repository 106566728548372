import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CampaignByUserRequestDTO, CampaignByUserResponseDTO } from 'src/app/data/dto/CampaignDTO';
import { CampaignRepository } from 'src/app/core/repositories/campaign/CampaignRepository';

@Injectable({
    providedIn: 'root'
})

export class ListCampaignByUserUseCase implements UseCase<CampaignByUserRequestDTO, CampaignByUserResponseDTO> {
    constructor(
        private campaignRepository: CampaignRepository,
    ) { }

    execute(idUser: CampaignByUserRequestDTO): Observable<CampaignByUserResponseDTO> {
        return this.campaignRepository.listByUser(idUser);
    }
}
