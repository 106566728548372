import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DownloadFileRepository } from '../repositories/DownloadFileRepository';
import { UseCase } from '../base/UserCase';
import { DownloadExcelDTO, DownloadExcelRequestDTO } from 'src/app/data/dto/InventoryDTO';

@Injectable({
  providedIn: 'root',
})
export class DownloadExcelUseCase implements UseCase<DownloadExcelRequestDTO, DownloadExcelDTO> {
  constructor(
    private downloadFileRepository: DownloadFileRepository
    ) {}

  execute(request: DownloadExcelRequestDTO): Observable<DownloadExcelDTO> {
    return this.downloadFileRepository.DownloadExcel(request);
  }
}
