import { ReportsAdminRepository } from 'src/app/core/repositories/reports/ReportsAdminRepository';
import { UseCase } from './../../base/UserCase';
import { Injectable } from '@angular/core';
import { ListScreenDTO } from 'src/app/data/dto/ReportsDTO';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
  })

  export class ListScreensUseCase implements UseCase<string, ListScreenDTO>{
      constructor(private reportsAdminRepository: ReportsAdminRepository){}
      execute(): Observable<ListScreenDTO> {
          return this.reportsAdminRepository.listScreen();
      }
  }
