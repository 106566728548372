import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CatalogRepository } from '../../repositories/catalog/CatalogRepository';
import { CatalogTypeDocumentDTO } from 'src/app/data/dto/CatalogDTO';

@Injectable({
    providedIn: 'root'
})

export class ListTypeDocumentUseCase implements UseCase<string, CatalogTypeDocumentDTO> {
    constructor(
        private catalogRepository: CatalogRepository,
    ) { }

    execute(): Observable<CatalogTypeDocumentDTO> {
        return this.catalogRepository.listTypeDocument();
    }

}
