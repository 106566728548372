import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InventoryRepository } from '../../repositories/inventory/InventoryRepository';
import { ProgramScreenRequestDTO, ProgramScreenResponseDTO } from 'src/app/data/dto/InventoryDTO';

@Injectable({
  providedIn: 'root',
})

export class ProgramScreenUseCase
  implements UseCase<ProgramScreenRequestDTO, ProgramScreenResponseDTO>
{
  constructor(private inventoryRepository: InventoryRepository) {}

  execute(
    request: ProgramScreenRequestDTO
  ): Observable<ProgramScreenResponseDTO> {
    return this.inventoryRepository.programScreen(request);
  }
}
