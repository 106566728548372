import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { UserRepository } from '../../repositories/auth/UserRepository';
import { Injectable } from '@angular/core';
import { RegisterRequestDTO, RegisterResponseDTO } from 'src/app/data/dto/LoginDTO';

@Injectable({
    providedIn: 'root'
})

export class RegisterUseCase implements UseCase<RegisterRequestDTO, RegisterResponseDTO> {
    constructor(
        private userRepository: UserRepository,
    ) { }

    execute(request: RegisterRequestDTO): Observable<RegisterResponseDTO> {
        return this.userRepository.register(request);
    }
}
