import { Observable } from 'rxjs';
import { ReportsAdminRepository } from 'src/app/core/repositories/reports/ReportsAdminRepository';
import { UseCase } from './../../base/UserCase';
import { Injectable } from '@angular/core';
import { ListCampaniaDTO } from 'src/app/data/dto/ReportsDTO';
@Injectable({
    providedIn: 'root',
  })

  export class ListCampaignUseCase implements UseCase<string, ListCampaniaDTO>{
      constructor(private reportsAdminRepository: ReportsAdminRepository){}
      execute(): Observable<ListCampaniaDTO> {
        return this.reportsAdminRepository.listCampaign();
      }
  }
