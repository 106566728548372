import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InventoryRepository } from '../../repositories/inventory/InventoryRepository';
import { UpdateAvailableRequestDTO, UpdateAvailableResponseDTO } from 'src/app/data/dto/InventoryDTO';

@Injectable({
    providedIn: 'root'
})

export class UpdateAvailableInventoryUseCase implements UseCase<UpdateAvailableRequestDTO, UpdateAvailableResponseDTO> {
    constructor(
        private inventoryRepository: InventoryRepository,
    ) { }

    execute(request: UpdateAvailableRequestDTO): Observable<UpdateAvailableResponseDTO> {
        return this.inventoryRepository.updateAvailableInventory(request);
    }
}
