import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DownloadFileRepository } from '../repositories/DownloadFileRepository';
import { UseCase } from '../base/UserCase';
import { DownloadInvoicesPDFDTO } from 'src/app/data/dto/InvoicesDTO';

@Injectable({
  providedIn: 'root',
})
export class DownloadInvoicesPDFUseCase implements UseCase<DownloadInvoicesPDFDTO, any> {
  constructor(
    private downloadFileRepository: DownloadFileRepository
    ) { }

  execute(request: DownloadInvoicesPDFDTO): Observable<any> {
    return this.downloadFileRepository.DownloadInvoicesPDF(request);
  }
}
