import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CampaignOkeyRequestDTO, CampaignOkeyResponseDTO } from 'src/app/data/dto/InventoryDTO';
import { InventoryRepository } from 'src/app/core/repositories/inventory/InventoryRepository';

@Injectable({
    providedIn: 'root'
})

export class OkeyCampaignUseCase implements UseCase<CampaignOkeyRequestDTO, CampaignOkeyResponseDTO> {
    constructor(
        private inventoryRepository: InventoryRepository,
    ) { }

    execute(request: CampaignOkeyRequestDTO): Observable<CampaignOkeyResponseDTO> {
        return this.inventoryRepository.okeyCampaign(request);
    }
}
