import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DownloadFileRepository } from '../repositories/DownloadFileRepository';
import { UseCase } from '../base/UserCase';
import { DownloadPDFDTO } from 'src/app/data/dto/InventoryDTO';

@Injectable({
  providedIn: 'root',
})
export class DownloadPDFUseCase implements UseCase<DownloadPDFDTO, any> {
  constructor(
    private downloadFileRepository: DownloadFileRepository
    ) { }

  execute(request: DownloadPDFDTO): Observable<any> {
    return this.downloadFileRepository.DownloadPDF(request);
  }
}
