import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BillingRepository } from 'src/app/core/repositories/invoice/BillingRepository';
import { BillingRequestDTO, BillingResponseDTO } from 'src/app/data/dto/InvoicesDTO';

@Injectable({
    providedIn: 'root'
})

export class InvoicesUserUseCase implements UseCase<BillingRequestDTO, BillingResponseDTO> {
    constructor(
        private billingRepository: BillingRepository,
    ) { }

    execute(request: BillingRequestDTO): Observable<BillingResponseDTO> {
        return this.billingRepository.invoicesUser(request);
    }
}
