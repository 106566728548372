import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InventoryRepository } from '../../repositories/inventory/InventoryRepository';
import {
  ListScheduleScreenRequestDTO,
  ListScheduleScreenResponseDTO
} from 'src/app/data/dto/InventoryDTO';

@Injectable({
    providedIn: 'root'
})

export class ListScheduleScreenUseCase
  implements
    UseCase<ListScheduleScreenRequestDTO, ListScheduleScreenResponseDTO>
{
  constructor( private inventoryRepository: InventoryRepository) { }

    execute(
      request: ListScheduleScreenRequestDTO
    ): Observable<ListScheduleScreenResponseDTO> {
        return this.inventoryRepository.listScheduleScreen(request);
    }
}
