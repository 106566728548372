import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { InvoiceRepository } from '../../repositories/invoice/InvoiceRepository';
import { ListDigitalSocioDTO } from 'src/app/data/dto/InvoicesDTO';

@Injectable({
  providedIn: 'root',
})

export class ListDigitalSocioUseCase
  implements UseCase<string, ListDigitalSocioDTO>
{
  constructor(private invoiceRepository: InvoiceRepository) {}

  execute(): Observable<ListDigitalSocioDTO> {
    return this.invoiceRepository.listDigitalSocio();
  }
}
