import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UploadTaxRepository } from '../../repositories/upload-tax/UploadTaxRepository';
import { FetchDocumentsRequestDTO, FetchDocumentsResponseDTO} from 'src/app/data/dto/UploadTaxDTO';

@Injectable({
    providedIn: 'root'
})

export class FetchDocumentsUseCase implements UseCase<FetchDocumentsRequestDTO, FetchDocumentsResponseDTO> {
    constructor(
        private uploadTaxRepository: UploadTaxRepository,
    ) { }

    execute(user: FetchDocumentsRequestDTO): Observable<FetchDocumentsResponseDTO> {
        return this.uploadTaxRepository.fetchDocuments(user);
    }
}
